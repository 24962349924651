var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "photo-book-cus-box",
    staticStyle: {
      "background-color": "#E8E4E4"
    }
  }, [_c('back-off', {
    attrs: {
      "url": _vm.backUrl,
      "title": "Diy"
    }
  }), _c('div', {
    staticStyle: {
      "width": "100vw",
      "background-color": "antiquewhite",
      "height": "2vw"
    }
  }), _vm._l(_vm.pictureInfoList, function (item, index) {
    return [_c('div', {
      key: index + 100,
      staticStyle: {
        "width": "100vw",
        "position": "relative",
        "background-color": "antiquewhite",
        "padding": "1vw"
      }
    }, [_c('div', {
      staticStyle: {
        "vertical-align": "top",
        "background-color": "#CECECE",
        "overflow": "hidden",
        "position": "relative",
        "touch-action": "pan-y!important",
        "margin": "0 auto"
      },
      style: {
        width: _vm.coverageWidth,
        height: _vm.coverageHeight
      },
      on: {
        "click": function ($event) {
          return _vm.selectPicture(index);
        }
      }
    }, [(_vm.pictureInfoList[index] ? _vm.pictureInfoList[index].coverImgs[0].coverImg : false) ? _c('van-image', {
      staticClass: "custom-cover-image-box",
      staticStyle: {
        "z-index": "10"
      },
      style: {
        width: _vm.coverageWidth,
        height: _vm.coverageHeight,
        top: '0px',
        left: '0px',
        position: 'absolute'
      },
      attrs: {
        "src": _vm.pictureInfoList[index].coverImgs[0].coverImg
      }
    }) : _vm._e(), _c('div', {
      style: {
        width: _vm.cusAreaW,
        height: _vm.cusAreaH,
        top: _vm.cusAreaY,
        left: _vm.cusAreaX,
        position: 'absolute'
      },
      attrs: {
        "id": 'custom-user-image-option-box-' + index + '-area'
      }
    }, [(_vm.pictureInfoList[index] ? _vm.pictureInfoList[index].userImgInfo[0].photoInfo.url : false) ? _c('van-image', {
      style: {
        top: _vm.pictureInfoList[index].userImgInfo[0].top,
        left: _vm.pictureInfoList[index].userImgInfo[0].left
      },
      attrs: {
        "src": _vm.pictureInfoList[index] ? _vm.pictureInfoList[index].userImgInfo[0].photoInfo.url : '0',
        "width": _vm.pictureInfoList[index] ? _vm.pictureInfoList[index].userImgInfo[0].photoInfo.width : '0',
        "height": _vm.pictureInfoList[index] ? _vm.pictureInfoList[index].userImgInfo[0].photoInfo.height : '0',
        "display": "inline-block"
      },
      scopedSlots: _vm._u([{
        key: "loading",
        fn: function () {
          return [_c('van-loading', {
            staticStyle: {
              "color": "red"
            },
            attrs: {
              "type": "spinner",
              "size": "50"
            }
          })];
        },
        proxy: true
      }], null, true)
    }) : _vm._e(), (_vm.pictureInfoList[index] ? _vm.pictureInfoList[index].userImgInfo[0].photoInfo.url : false) ? _c('div', {
      staticClass: "custom-user-image-option-box",
      class: 'custom-user-image-option-box-' + index,
      style: {
        width: _vm.pictureInfoList[index] ? _vm.pictureInfoList[index].userImgInfo[0].photoInfo.width : '0px',
        height: _vm.pictureInfoList[index] ? _vm.pictureInfoList[index].userImgInfo[0].photoInfo.height : '0px',
        top: _vm.pictureInfoList[index].userImgInfo[0].top,
        left: _vm.pictureInfoList[index].userImgInfo[0].left
      },
      attrs: {
        "id": 'custom-user-image-option-box-' + index
      }
    }) : _vm._e()], 1)], 1), _c('div', {
      staticStyle: {
        "margin-top": "2vw",
        "margin-bottom": "2vw",
        "display": "flex",
        "align-items": "center",
        "justify-content": "center"
      }
    }, [_c('span', {
      staticStyle: {
        "margin-right": "2vw"
      }
    }, [_vm._v("Count:")]), _c('span', {
      staticStyle: {
        "margin-right": "2vw"
      }
    }), _vm._v(" " + _vm._s(_vm.pictureInfoList[index].count) + " "), _c('span', {
      staticStyle: {
        "margin-left": "2vw"
      }
    })])])];
  }), _c('br'), _c('br'), _c('br')], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };